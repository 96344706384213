import * as React from "react";
// @ts-ignore
import headerPic from '../../../static/images/nb0.jpg';
// @ts-ignore
import shapeImage from '../../../static/images/header_shape.svg';

export default class HeaderSection extends React.Component {
    render() {
        return (
            <section className="header">
                <div className="container">
                    <div className="info">
                        <h1 className="title">Удобный софт</h1>
                        <p>Проектирование, разработка и техническое сопровождение информационных систем любой сложности.
                        </p>
                    </div>
                    <div className="picture">
                        <img src={headerPic}/>
                    </div>
                </div>
                <img className="shape" src={shapeImage}/>
            </section>
        )
    }
}
